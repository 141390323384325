@import '../../consts/variables';

.iconCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba($clr-gray, 60%);
  border-radius: 50%;
  &Small {
    width: 36px;
    height: 36px;
  }
  &Big {
    width: 46px;
    height: 46px;
  }
}

.limitedText {
  max-width: 240px;
}

.barItem {
  border-bottom: 1px solid rgba($clr-gray, 30%);
  padding-block: 0 20px;
}

.noSubscriptionsWarning {
  color: #B9472F;
}

.linkStyle {
  color: #0000FF;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}