@import '../../consts/variables';

.footer {
  padding-block: 102px 95px;
  background: rgba($clr-black, 1);

  &Inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      "contacts contacts navmenu1 navmenu2"
      "contacts contacts social social";
  }

  &Contacts {
    grid-area: contacts;

    &Button {
      align-self: start;
    }
  }
  &NavMenu1 {
    grid-area: navmenu1;
  }
  &NavMenu2 {
    grid-area: navmenu2;
  }
  &Social {
    margin-top: 93px;
    grid-area: social;
  }
}