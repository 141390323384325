.workerInfoBox {
  padding: 40px 32px 40px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.workerContent {
  max-width: 1480px;
  width: 100%;
}

.editButton {
  color: #000000;
  padding: 14px 20px;
  margin: 0;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #ddd;
  font-weight: bold;
}

.editButton span {
  font-family: 'Gilroy';
  display: inline-block;
  margin-left: 5px;
}

.editButton img {
  display: inline-block;
  margin: 0;
}

.label {
  color: #b5b5b5;
  font-family: 'Gilroy';
}

.info {
  font-family: 'Gilroy';
  margin-bottom: 10px;
}

.workerPhoto {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: block;
  object-fit: cover;
}

.workerContentBlock {
  gap: 100px;
}

@media screen and (max-width: 900px) {
  .workerPhoto {
    width: 60px;
    height: 60px;
  }

  .workerContent {
    display: block;
  }

  .workerContentBlock {
    gap: 20px;
  }

  .workerInfoBox {
    padding: 20px;
  }
}
