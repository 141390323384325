.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container * {
  font-family: 'Gilroy';
}

.blockContent {
  max-width: 1480px;
  width: 100%;
  padding: 60px 32px;
}

.blockContent h1 {
  font-size: 60px;
  margin-bottom: 60px;
  font-weight: bold;
}

.text {
  margin-bottom: 20px;
}

.blockContent button {
  padding: 12px;
  color: #ffffff;
  padding: 14px 20px;
  margin: 0;
  background-color: #000000;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  display: block;
}

@media screen and (max-width: 900px) {
  .blockContent {
    padding: 24px;
  }

  .blockContent h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
