.container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: #fff;
  }
  
  .title {
    text-align: center;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .button {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: red;
    text-align: center;
  }
  
  .welcome-back {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .password-reminder {
    font-size: 0.9em;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .password-reminder-block {
    padding: 10px;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
  }
  