@import '../../consts/variables';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 200ms all;
  cursor: pointer;
  border: none;
  background: none;
  padding-block: 15px;
  padding-inline: 30px;
  border-radius: 4px;
  font-weight: 700;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.9;
  }
  &.thin {
    padding-block: 15px;
  }
  &.medium {
    padding-block: 20px;
  }
  &.thick {
    padding-block: 30px;
  }
  &.short {
    padding-inline: 20px;
  }
  &.gapSmall {
    gap: 6px;
  }
  &.gapLarge {
    gap: 10px;
  }

  &Black {
    background: rgba($clr-black, 1);
    color: rgba($clr-white, 1);

    &.disabled {
      background: rgba($clr-black, 75%);
    }

    &:hover:not(.disabled) {
      background: rgba($clr-black, 90%);
      box-shadow: rgba($clr-gray, 15%) 2px 2px 2px 0;
    }
  }

  &Gray {
    // background: rgba($clr-gray, 1);
    background: rgba($clr-gray, 0.3);
    color: $clr-black;

    &.disabled {
      // background: rgba($clr-gray, 75%);
      background: rgba($clr-gray, 0.1);
      color: rgba($clr-black, 0.3);
    }

    &:hover:not(.disabled) {
      background: rgba($clr-gray, 0.15);
      box-shadow: rgba($clr-gray, 0.15) 2px 2px 2px 0;
    }
  }

  &White {
    background: rgba($clr-white, 1);
    color: rgba($clr-black, 1);

    &.disabled {
      background: rgba($clr-white, 75%);
    }

    &:hover:not(.disabled) {
      background: rgba($clr-white, 90%);
      box-shadow: rgba($clr-gray, 15%) 2px 2px 2px 0;
    }
  }

  &Outline {
    border: 1px solid rgba($clr-gray, 30%);

    &:hover:not(.disabled) {
      background: rgba($clr-gray, 5%);
      box-shadow: rgba($clr-gray, 15%) 2px 2px 2px 0;
    }
  }

  &Underline {
    padding-inline: 4px;
    padding-block: 4px;
    border-bottom: 1px solid rgba($clr-gray, 15%);
    border-radius: 4px 4px 0 0;

    &:hover {
      border-bottom: 1px solid rgba($clr-gray, 60%);
      box-shadow: rgba($clr-gray, 15%) 0px 2px 2px;
    }
  }

  &Text {
    padding-inline: 0;
    padding-block: 0;
    border-radius: none;
  }
}
