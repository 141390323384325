@import '../../../../consts/variables';

.card {
  border-bottom: 1px solid rgba($clr-gray, 0.6);
  max-width: 500px;
  margin: 0 auto;
  box-shadow: 1px 0 5px #ededf2;
  padding: 0;
  padding-block: 0 !important;
  background-color: #ffffff;

  @media (min-width: $desktop-width) {
    border-bottom: none;
  }

  &BtnBlock {
    align-self: center;
    width: 100%;
  }

  &Btn {
    display: flex;
    justify-content: center;
    // padding-block: 4px;
    background-color: transparent;
    border-radius: 4px;
    transition: 0.3s background-color ease-in-out;
    background: rgb(255, 165, 0);
    padding: 20px;
    border-radius: 10px;

    & p {
      transition: 0.3s color ease-in-out;
      color: #ffffff;
    }

    &:hover {
      background: rgba(255, 165, 0, 70%);

      & p {
        transition: 0.3s color ease-in-out;
        color: $clr-white;
      }
    }
  }
}

.title {
  background-color: #000000;
  background-image: url('./../../../../static/bg/card_bg.png');
  background-size: cover;
  width: 100%;
  // min-height: 87px;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 30px;
}

.title * {
  text-align: center;
}

.priceText {
  width: 100%;
}

.cardContent {
  padding: 20px;
}

.infoText,
.description {
  color: #525261;
}
