.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container * {
  font-family: 'Gilroy';
}

.blockContent {
  max-width: 1480px;
  width: 100%;
  padding: 60px 32px;
}

.blockContent h1 {
  font-size: 60px;
  margin-bottom: 60px;
  font-weight: bold;
}

.filters {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
  padding: 10px 0;
}

.filters label {
  color: #909090;
}

.filters input[type='number'] {
  width: 80px;
  padding: 8px;
  margin-left: 10px;
  border-radius: 4px;
  transition: border-color 0.3s;
  background-color: #eaeaea;
  color: #000000;
}

.filters input[type='number']:focus {
  border-color: #007bff;
  outline: none;
}

.filters input[type='number']::-webkit-inner-spin-button,
.filters input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.workersListContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  align-items: center;
}

.workerAttribute {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-weight: bold;
  width: 100%;
}

.label {
  font-weight: normal;
  color: #909090;
}

.hr {
  height: 1px;
  width: 100%;
  background-color: #e6e6e6;
}

.workerName {
  margin-bottom: 10px;
  font-weight: bold;
  width: 100%;
}

.workerName span {
  display: block;
  margin-bottom: 10px;
  font-weight: normal;
  color: #909090;
}

.workerTitle {
  width: 100%;
}

.workerItem {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #e6e6e6;
}

.workerDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.workerName {
  font-weight: bold;
  margin-bottom: 5px;
}

.workersListContainer ul {
  list-style-type: none;
  padding: 0;
}

.workerItem {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

.workerDetails > div {
  margin-bottom: 10px;
}

.workerProblems,
.workerHistory {
  margin-top: 20px;
}

.problemDetails,
.historyItem {
  background-color: #f9f9f9;
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
}

.currentProblems > div,
.currentProblemsHistory li {
  border-left: 4px solid #007bff; /* Синий для текущих */
}

.finishedProblems > div,
.finishedProblemsHistory li {
  border-left: 4px solid #28a745; /* Зеленый для завершенных */
}

.workerPhoto {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

select {
  margin-top: 10px;
}

@media (max-width: 900px) {
  .blockContent {
    padding: 20px;
  }

  .blockContent h1 {
    font-size: 24px;
  }

  .workersListContainer {
    grid-template-columns: repeat(1, 1fr);
  }

  .filters {
    display: block;
  }

  .filters label {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 5px;
  }
}