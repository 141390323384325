@import '../../consts/variables';

.propertyBlock {
  background: rgba($clr-gray, 15%);
  border-radius: 4px;
  padding: 20px 25px 20px;

  @media (min-width: $desktop-width) {
    padding: 70px 0;
  }
}

.propertyCard {
  padding-bottom: 20px;

  @media (min-width: $desktop-width) {
    padding-bottom: 0;
    width: 320px;
  }
}

.propertyMainInfo {
  display: grid;
  gap: 14px 18px;
  grid-template-columns: repeat(2, 1fr);
}

.propertyCardImage {
  display: block;
  width: 100%;
  border-radius: 10px;
  max-height: 282px;
  object-fit: cover;
  grid-column: span 2;
}

.clampable {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (max-width: 900px) {
  .manageHouseButton button {
    width: 100%;
  }
}
