@import '../../consts/variables';

.iconButton {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  transition: 200ms all;
  background: none;

  &.small {
    width: 24px;
    height: 24px;
  }
  &.medium {
    width: 36px;
    height: 36px;
  }
  &.large {
    width: 50px;
    height: 50px;
  }

  &Gray {
    background: rgba($clr-gray, 15%);

    &:hover, &.active {
      background: rgba($clr-gray, 30%);
      box-shadow: rgba($clr-gray, 15%) 0px 4px 4px;
    }
  }

  &Outline {
    border: 1px solid rgba($clr-gray, 60%);

    &:hover, &.active {
      box-shadow: rgba($clr-gray, 15%) 0px 4px 4px;
      background: rgba($clr-black, 1);
      color: rgba($clr-white, 1);
    }
  }

  &Transparent {
    &:hover, &.active {
      background: rgba($clr-gray, 15%);
    }
  }
}