.typesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.typesContainer * {
  font-family: 'Gilroy';
}

.blockContent {
  max-width: 1480px;
  width: 100%;
  padding: 60px 32px;
}

.blockContent h1 {
  font-size: 60px;
  margin-bottom: 60px;
  font-weight: bold;
}

.subsList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(33% - 20px), 1fr));
  grid-gap: 20px;
}

.subCard {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #e6e6e6;
}

.info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-weight: bold;
}

.label {
  font-weight: normal;
  color: #909090;
}

.title {
  font-weight: bold;
  font-size: 24px;
  padding: 10px 0;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 10px;
}

.subCard button {
  padding: 12px 15px;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid #e6e6e6;
  color: #000000;
  cursor: pointer;
  font-weight: bold;
}

.editButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.addSubContainer {
  background-color: #f2f2f2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.addHeader h2 {
  margin-bottom: 40px;
  font-size: 36px;
}

.addSubInput input {
  background-color: #ffffff;
  border: none;
  margin-bottom: 10px;
}

.addButton {
  padding: 12px 15px;
  border-radius: 5px;
  background-color: #000000;
  color: #ffffff;
  cursor: pointer;
  font-weight: bold;
  margin-right: 10px;
}

.cancelButton {
  padding: 12px 15px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  color: #000000;
  cursor: pointer;
  font-weight: bold;
}

.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  max-height: 100vh;
  width: 80vw;
  padding: 10px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1050;
  overflow-y: auto;
}

.Modal .cancelButton,
.Modal .saveButton {
  padding: 12px;
  color: #000000;
  padding: 14px 20px;
  margin: 0;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  border: 1px solid #000000;
  display: block;
  width: 100%;
}

.Modal .saveButton {
  background-color: #000000;
  border: none;
  color: #fff;
}

.errorMessage {
  color: orange;
  margin-bottom: 10px;
}

@media screen and (max-width: 900px) {
  .subsList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    grid-gap: 10px;
  }

  .addFields {
    display: block;
  }

  .Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.9);
    max-height: 100vh;
    width: 90vw;
    padding: 10px;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1050;
    overflow-y: auto;
  }

  .blockContent {
    padding: 24px;
  }

  .blockContent h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .addHeader h2 {
    font-size: 20px;
    font-weight: normal;
  }

  .addHeader svg {
    width: 24px;
    height: 24px;
  }

  .title {
    font-size: 20px;
  }
}
