.blur {
  position: fixed;
  z-index: 5000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.delete_block {
  padding: 10px;
  z-index: 5000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 327px;
  height: 235px;
  border-radius: 20px;
  background: #FFF;
}

.block_title h2 {
  color: #000;
  font-family: Gilroy;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.block_text {
  width: 179px;
  margin: 10px 0 50px;
  text-align: center;
}
.block_text span {
  color: #000;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}

.block_buttons {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.cancel {
  color: #000;
  text-align: center;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 151px;
  height: 44px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border-radius: 20px;
  background: #E1E1E1;
}

.delete {
  color: #FFF;
  text-align: center;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 151px;
  height: 44px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border-radius: 20px;
  background: #000;
}