@font-face {
  font-family: 'Gilroy';
  src: url('./../fonts/Gilroy-Medium.eot');
  src: url('./../fonts/Gilroy-Medium.eot') format('embedded-opentype'),
    url('./../fonts/Gilroy-Medium.woff') format('woff'),
    url('./../fonts/Gilroy-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'GilroyBold';
  src: url('./../fonts/Gilroy-Bold.eot');
  src: url('./../fonts/Gilroy-Bold.eot') format('embedded-opentype'),
    url('./../fonts/Gilroy-Bold.woff') format('woff'),
    url('./../fonts/Gilroy-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'GilroyRegular';
  src: url('./../fonts/Gilroy-Regular.eot');
  src: url('./../fonts/Gilroy-Regular.eot') format('embedded-opentype'),
    url('./../fonts/Gilroy-Regular.eot') format('woff'),
    url('./../fonts/Gilroy-Regular.eot') format('truetype');
}

.addHouseContainer {
  display: flex;
  flex-direction: column;
  padding: 20px 20%;
  width: 100%;
  margin: 0 auto;
  background: #fff;
  font-family: 'Gilroy';
}

.addHouseContainer h1 {
  margin-bottom: 40px;
  font-size: 50px;
  font-family: 'Gilroy';
}

.addHouseContainer input,
.addHouseContainer select {
  padding: 12px;
  margin-bottom: 20px;
  background-color: #f6f6f6;
  border-radius: 5px;
  border: none;
  display: block;
  width: 100%;
}

.addHouseContainerLabel {
  margin-bottom: 10px;
  color: #909090;
}

.addHouseContainer button {
  color: #ffffff;
  padding: 14px 20px;
  margin: 0;
  background-color: #000000;
  border-radius: 5px;
  cursor: pointer;
}

.addHouseContainer select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"/></svg>')
    no-repeat;
  background-position: right 10px center;
  background-color: #f6f6f6;
  padding-right: 30px;
}

.mapContainer {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
  width: 100%;
  height: 400px;
}

/* .addHouseContainer input[type='file'] {
  visibility: hidden;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
} */

.addHouseContainer input[type='file'] {
  background-color: transparent;
  position: relative;
  cursor: pointer;
  color: #909090;
}

.addHouseContainer input[type='file']::file-selector-button {
  border: none;
  outline: none;
  background-color: transparent;
  border-bottom: 1px solid #909090;
  width: fit-content;
  padding-bottom: 10px;
  margin-left: 5px;
  color: #000000;
}

.addHouseContainer input[type='file']::before {
  content: url('./img/uiw_paper-clip.svg');
  width: 18px;
  height: 19px;
  display: inline-block;
  position: absolute;
  top: 20%;
  left: 1px;
}

.errorMessage {
  color: orange;
  margin-bottom: 10px;
  margin-top: -10px;
}

.addHouseContainer button[type='submit']:disabled {
  opacity: 0.8;
}

@media screen and (max-width: 900px) {
  .addHouseContainer {
    padding: 20px;
  }

  .addHouseContainer h1 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  .addHouseContainer button[type='submit'] {
    width: 100%;
  }
}
