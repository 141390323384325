.assignedProblemsContainer {
  padding: 10px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blockContent {
  max-width: 1480px;
  width: 100%;
}

.header {
  font-size: 36px;
  color: #909090;
  padding-bottom: 20px;
  border-bottom: 3px solid #000000;
  margin-bottom: 40px;
}

.houseProblemsContainer {
  padding: 50px;
}

.houseProblemsListContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.houseProblemsPrivateContainer {
  flex: 0 0 calc(25% - 20px);
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #f8f8f8;
}

.houseProblemsPrivateContainer * {
  font-family: 'Gilroy';
}

.assignButton {
  margin-top: 10px;
}

.houseProblemsHeader {
  margin-top: 3px;
}

.outerWorkerBox {
  border: 1px solid #ddd;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 20px;
  border-radius: 10px;
}

:global(.leaflet-popup-content-wrapper) {
  width: 600px !important;
  height: 400px !important;
  overflow-y: auto !important;
}

:global(.leaflet-popup-content) {
  width: 100% !important;
  height: 100% !important;
}

.popupContent {
  font-family: Arial, sans-serif;
  color: #333;
  max-width: 100%;
  line-height: 1.5;
  height: 100%;
}

.problemBox {
  background-color: white;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: 5px;
}

.assignedProblem {
  background-color: #75b1cf;
}

.problemsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  grid-gap: 10px;
}

.info {
  margin-bottom: 10px;
  font-weight: bold;
}

.infoLabel {
  color: #909090;
  font-weight: normal;
}

@media (min-width: 600px) {
  .problemsGrid {
    grid-template-columns: repeat(4, 1fr);

    .infoBox {
      width: 100%; /* Занимает всю доступную ширину */
      height: auto; /* Высота блока адаптируется под контент */
      overflow-y: visible; /* Убираем прокрутку, так как блок будет подстраиваться под контент */
    }
  }
}

.infoBoxTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.houseInfoContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.houseImageContainer {
  flex: 0 0 40%;
}

.houseImage {
  width: 90%;
  max-height: 300px;
  border-radius: 8px;
}

.houseDetailsContainer {
  flex: 0 0 35%;
}

.houseDetailItem {
  margin-bottom: 10px;
}

.ownerInfoContainer {
  background-color: #c7bbc493;
  border-radius: 8px;
  padding: 30px;
}

.ownerInfoTitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.ownerDetailItem {
  margin-bottom: 5px;
}

.problemsContainer {
  margin-top: 25px;
}

.problemsTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.problemImage {
  width: 100%;

  max-height: 180px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
}

.problemDetails {
  margin-top: 10px;
}

.problemDetailItem {
  margin-bottom: 5px;
}

.workerProblemBox {
  margin: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: white;
  padding: 20px;
  transition: all 0.3s ease;
}

.workerProblemBox:hover {
  transform: translateY(5px);
}

.uploadButton {
  display: inline-block;
  padding: 12px 15px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 5px;
}

.uploadButton:hover {
  background-color: #e0e0e0;
}

.finishButton {
  padding: 12px 15px;
  background-color: #48ae4c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.deleteButton {
  padding: 12px 15px;
  background-color: #e74444;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 5px;
  font-weight: bold;
}

.openButton {
  padding: 12px 15px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: 1px solid #000000;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 5px;
  font-weight: bold;
}

.finishButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.previewContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.previewImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
}

.statusMessage {
  padding: 10px;
  margin-top: 10px;
  border-radius: 4px;
}

.success {
  background-color: #d4edda;
  color: #155724;
}

.error {
  background-color: #f8d7da;
  color: #721c24;
}

.warningMessage,
.successMessage {
  margin-bottom: 5px;
}

.assignedProblemsContainer input[type='file'] {
  background-color: transparent;
  position: relative;
  cursor: pointer;
  color: #909090;
  margin-bottom: 10px;
}

.assignedProblemsContainer input[type='file']::file-selector-button {
  border: none;
  outline: none;
  background-color: transparent;
  border-bottom: 1px solid #909090;
  width: fit-content;
  padding-bottom: 10px;
  margin-left: 20px;
  color: #000000;
  cursor: pointer;
}

.assignedProblemsContainer input[type='file']::before {
  content: url('./../../components/img/uiw_paper-clip.svg');
  width: 18px;
  height: 19px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 1px;
}

@media (min-width: 600px) {
  .problemsGrid {
    grid-template-columns: repeat(4, 1fr);

    .infoBox {
      width: 100%; /* Занимает всю доступную ширину */
      height: auto; /* Высота блока адаптируется под контент */
      overflow-y: visible; /* Убираем прокрутку, так как блок будет подстраиваться под контент */
    }
  }
}

@media screen and (max-width: 900px) {
  .assignedProblemsContainer {
    padding: 20px;
  }

  .header {
    font-size: 20px;
  }
}