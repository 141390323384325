@import '../consts/variables';

.profileBlock {
  margin-top: 50px;

  @media (min-width: $desktop-width) {
    margin-top: 60px;
  }
}

.propertyBlock {
  margin-top: 40px;

  @media (min-width: $desktop-width) {
    margin-top: 60px;
  }
}

.activeSubscriptionsBlock {
  margin-top: 40px;

  @media (min-width: $desktop-width) {
    margin-top: 70px;
  }
}

.availableSubscriptionsBlock {
  margin-top: 40px;

  @media (min-width: $desktop-width) {
    margin-top: 70px;
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.clientPage {
  padding-bottom: 30px;

  @media (min-width: $desktop-width) {
    padding-bottom: 0;
  }
}