.blur {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.blur_none {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
}

.card_block {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 3000;
  background: #FFF;
  overflow-y: scroll;
  height: 100%;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  border-radius: 20px;
  width: 390px;
}
.card_block::-webkit-scrollbar-track {
  background-color: transparent;
}
.card_block::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.card_block::-webkit-scrollbar {
  width: 0;
}

.content_container {
  padding: 0 !important;
}

.header {
  margin: 20px 0;
  width: 100%;
  padding: 0 10px;
}

.header_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header__title h2 {
  color: #000;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.header_exit,
.header_search {
  cursor: pointer;
  height: 100%;
}

.block_image {
  background-size: cover;
  background-position: center;
  padding: 10px;
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

.block_type span {
  color: #FFF;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 5px 15px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  border-radius: 60px;
  background: #000;
}

.block_title_date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 20px 0 60px;
}
.block_title_date h2 {
  color: #000;
  font-family: Gilroy;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.block_title_date span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  opacity: 0.5;
  color: #000;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.block_title_date span::before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  background-image: url("/public/img/svg/CalendarAdd.svg");
  margin-right: 5px;
}

.block_description {
  margin-bottom: 15px;
  padding-bottom: 50px;
  border-bottom: 2px solid rgba(58, 58, 58, 0.1);
}
.block_description span {
  display: inline-block;
  margin-bottom: 10px;
  opacity: 0.5;
  color: #000;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.block_description p {
  color: #000;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.block_status, .block_type_problem {
  border-bottom: 2px solid rgba(58, 58, 58, 0.1);
  padding-bottom: 15px;
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.block_status span, .block_type_problem span {
  color: rgba(0, 0, 0, 0.5);
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.block_status span:last-child, .block_type_problem span:last-child {
  color: #000;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.block_time {
  border-bottom: 2px solid rgba(58, 58, 58, 0.1);
  padding-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.block_time span {
  color: #000;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.block_buttons {
  border-radius: 5px;
  background: #FFF;
  width: 100%;
  height: 100%;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.edit {
  cursor: pointer;
  margin-right: 5px;
  width: 198px;
  height: 50px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border-radius: 10px;
  background: #F2F2F2;
}
.edit span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #000;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.edit span::before {
  content: "";
  display: block;
  background-image: url("/public/img/svg/pen.svg");
  width: 17px;
  height: 17px;
  margin-right: 5px;
}

.delete {
  cursor: pointer;
  width: 77px;
  height: 50px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border-radius: 10px;
  background: #F2F2F2;
}