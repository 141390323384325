.mobileLinks {
  margin-bottom: 20px;
  padding: 0 20px;
}

.mobileLinks li {
  padding-bottom: 5px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 5px;
}
