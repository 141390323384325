@import '../consts/variables';

.rootContainer {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.houseProfileBlock {
  padding-top: 50px;

  @media (min-width: $desktop-width) {
    padding-top: 40px;
  }
}

.problemSolvingBlock {
  margin-top: 50px;

  @media (min-width: $desktop-width) {
    margin-top: 70px;
  }
}

.currentProblemsBlock {
  margin-top: 50px;

  @media (min-width: $desktop-width) {
    margin-top: 70px;
  }
}

.problemsInProgressBlock {
  margin-top: 50px;

  @media (min-width: $desktop-width) {
    margin-block: 70px 100px;
  }
}

