@import '../../consts/variables';

.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  max-height: 110vh;
  width: 80vw;
  padding: 10px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1050;
  overflow-y: auto;
}

.displayNone {
  display: none;
}

.problemImageContainer {
  position: relative;
  width: 100%;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;

  @media (min-width: $desktop-width) {
    height: 300px;
  }
}

.problemImage {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.problemTitle {
  word-wrap: break-word;
  max-width: 75%;
}

.problemBubble {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: rgba($clr-black, 1);
  border-radius: 8px;
  padding: 5px 15px;
}

.shiftedText {
  margin-top: 5px;
}

.problemDate {
  opacity: 0.6;
}

.problemEditButton {
  background: rgba($clr-gray, 0.15);
  flex-grow: 1;
  border-radius: 10px;
  transition: 0.3s background ease-in-out;

  &:hover {
    background: rgba($clr-gray, 0.3);
  }

  @media (min-width: $desktop-width) {
    padding: 12px 16px;
  }
}

.problemDeleteButton {
  background: rgba($clr-gray, 0.15);
  padding: 15px 30px;
  border-radius: 10px;
  transition: 0.3s background ease-in-out;

  &:hover {
    background: rgba($clr-gray, 0.3);
  }

  @media (min-width: $desktop-width) {
    padding: 12px;
  }
}

.fullWidth {
  width: 100%;
}

@media screen and (max-width: 900px) {
  .Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.9);
    max-height: 99vh;
    width: 87vw;
    padding: 10px;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1050;
    overflow-y: auto;
  }

  .problemImageContainer {
    height: 180px;
  }

  .problemImageContainer img {
    object-fit: cover;
  }
}
