/* WorkerPage.module.css */
@font-face {
  font-family: 'Gilroy';
  src: url('./../fonts/Gilroy-Medium.eot');
  src: url('./../fonts/Gilroy-Medium.eot') format('embedded-opentype'),
    url('./../fonts/Gilroy-Medium.woff') format('woff'),
    url('./../fonts/Gilroy-Medium.ttf') format('truetype');
}

body {
  max-width: 100%;
  overflow-x: hidden;
  font-family: 'Gilroy';
}

.pageContainer {
  padding: 72px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pageContainerContent {
  width: 100%;
}

.dashboardContainer {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 20px;
}

.blockContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.blockContent > div {
  max-width: 1480px;
  width: 100%;
}

.pageHeader {
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 80px;
  padding-left: 32px;
}

.linkHighlight:hover {
  background-color: #ecd713;
  cursor: pointer;
}

.workerMap {
  flex: 1;
  margin-right: 20px;
  height: 750px;
}

.infoBox {
  flex: 1;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  height: 850px;
  width: 750px; /* Фиксированная ширина окна */
  overflow-y: auto; /* Добавление вертикальной прокрутки при необходимости */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Необязательно, добавляет тень вокруг блока */
}

.workerInfoBox,
.houseProblemDetailsBox,
.workerFormBox {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 20px;
  background: white;
  margin-bottom: 20px;
}

.assignedProblemsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background: #f8f8f8;
}

/* TABS */
.tab {
  padding: 10px;
  border: none;
  background-color: #ccc;
  cursor: pointer;
}

.activeTab {
  padding: 10px;
  border: none;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

.subTab {
  padding: 5px;
  margin-right: 5px;
  margin-top: 15px;
  background-color: #ddd;
  cursor: pointer;
}

.activeSubTab {
  padding: 5px;
  margin-right: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

/* TABS */

.problemDetail {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.workerPhoto {
  width: 150px;
  height: 150px;
}

.problemPhoto {
  width: 50px;
  height: 150px;
  object-fit: scale-down;
  background-color: #f8f8f8;
  margin-bottom: 10px;
}

.houseProblemsContainer {
  padding: 50px;
}

.houseProblemsListContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.houseProblemsPrivateContainer {
  flex: 0 0 calc(33% - 20px);
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(8, 8, 8, 0.24);
  border-radius: 5px;
  padding: 10px;
  background-color: #f8f8f8;
}

.assignButton {
  margin-top: 10px;
}

.houseProblemsHeader {
  margin-top: 3px;
}

.outerWorkerBox {
  border: 1px solid #ddd;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 20px;
  border-radius: 10px;
}

:global(.leaflet-popup-content-wrapper) {
  width: 600px !important;
  height: 400px !important;
  overflow-y: auto !important;
}

:global(.leaflet-popup-content) {
  width: 100% !important;
  height: 100% !important;
}

.popupContent {
  font-family: Arial, sans-serif;
  color: #333;
  max-width: 100%;
  line-height: 1.5;
  height: 100%;
}

.problemBox {
  background-color: white;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: 5px;
}

.assignedProblem {
  background-color: #75b1cf;
}

.problemsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  grid-gap: 10px;
}

@media (min-width: 600px) {
  .problemsGrid {
    grid-template-columns: repeat(4, 1fr);

    .infoBox {
      width: 100%; /* Занимает всю доступную ширину */
      height: auto; /* Высота блока адаптируется под контент */
      overflow-y: visible; /* Убираем прокрутку, так как блок будет подстраиваться под контент */
    }
  }
}

.infoBoxTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.houseInfoContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.houseImageContainer {
  flex: 0 0 40%;
}

.houseImage {
  width: 90%;
  max-height: 300px;
  border-radius: 8px;
}

.houseDetailsContainer {
  flex: 0 0 35%;
}

.houseDetailItem {
  margin-bottom: 10px;
}

.ownerInfoContainer {
  background-color: #c7bbc493;
  border-radius: 8px;
  padding: 30px;
}

.ownerInfoTitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.ownerDetailItem {
  margin-bottom: 5px;
}

.problemsContainer {
  margin-top: 25px;
}

.problemsTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.problemImage {
  width: 100%;

  max-height: 180px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
}

.problemDetails {
  margin-top: 10px;
}

.problemDetailItem {
  margin-bottom: 5px;
}

.workerProblemBox {
  margin: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: white;
  padding: 20px;
  transition: all 0.3s ease;
}

.workerProblemBox:hover {
  transform: translateY(5px);
}

.uploadButton {
  display: inline-block;
  padding: 10px 15px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.uploadButton:hover {
  background-color: #e0e0e0;
}

.finishButton {
  padding: 10px 15px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.finishButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.previewContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.previewImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
}

.statusMessage {
  padding: 10px;
  margin-top: 10px;
  border-radius: 4px;
}

.success {
  background-color: #d4edda;
  color: #155724;
}

.error {
  background-color: #f8d7da;
  color: #721c24;
}

.mobileLinks {
  margin-bottom: 20px;
  padding: 0 20px;
}

.mobileLinks li {
  padding-bottom: 5px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 5px;
}

@media screen and (max-width: 900px) {
  .pageHeader {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .pageContainer {
    padding: 50px 0;
  }
}
