@import '../../../../consts/variables';

.problemList {
  background: rgba($clr-gray, 0.15);
  padding: 20px;
  border-radius: 4px;
  cursor: pointer;

  @media (min-width: $desktop-width) {
    border-radius: 8px;
    padding: 30px 40px 40px;
  }
}

.problemListHeader {
  padding-block: 10px;
  border-bottom: 1px solid rgba($clr-gray, 0.15);
}

.problemListImages {
  margin-top: 30px;

  @media (min-width: $desktop-width) {
    margin-top: 0;
  }
}

.problemListDivider {
  background: rgba($clr-gray, 0.15);
  margin-left: -10px;
}

.smallProblemImage {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  object-fit: cover;

  @media (min-width: $desktop-width) {
    width: 60px;
    height: 60px;
  }
}

.growable {
  flex-grow: 1;
}

.expandArrow {
  margin-top: 26px;
}

