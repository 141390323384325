@font-face {
  font-family: 'Gilroy';
  src: url('../../../public/fonts/Gilroy-Regular.eot');
  src: local('Gilroy Regular'), local('Gilroy-Regular'),
    url('../../../public/fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/Gilroy-Regular.woff2') format('woff2'),
    url('../../../public/fonts/Gilroy-Regular.woff') format('woff'),
    url('../../../public/fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../../public/fonts/Gilroy-ExtraboldItalic.eot');
  src: local('Gilroy Extrabold Italic'), local('Gilroy-ExtraboldItalic'),
    url('../../../public/fonts/Gilroy-ExtraboldItalic.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/Gilroy-ExtraboldItalic.woff2') format('woff2'),
    url('../../../public/fonts/Gilroy-ExtraboldItalic.woff') format('woff'),
    url('../../../public/fonts/Gilroy-ExtraboldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../../public/fonts/Gilroy-Bold.eot');
  src: local('Gilroy Bold'), local('Gilroy-Bold'),
    url('../../../public/fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/Gilroy-Bold.woff2') format('woff2'),
    url('../../../public/fonts/Gilroy-Bold.woff') format('woff'),
    url('../../../public/fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../../public/fonts/Gilroy-Black.eot');
  src: local('Gilroy Black'), local('Gilroy-Black'),
    url('../../../public/fonts/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/Gilroy-Black.woff2') format('woff2'),
    url('../../../public/fonts/Gilroy-Black.woff') format('woff'),
    url('../../../public/fonts/Gilroy-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../../public/fonts/Gilroy-Light.eot');
  src: local('Gilroy Light'), local('Gilroy-Light'),
    url('../../../public/fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/Gilroy-Light.woff2') format('woff2'),
    url('../../../public/fonts/Gilroy-Light.woff') format('woff'),
    url('../../../public/fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../../public/fonts/Gilroy-Semibold.eot');
  src: local('Gilroy Semibold'), local('Gilroy-Semibold'),
    url('../../../public/fonts/Gilroy-Semibold.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/Gilroy-Semibold.woff2') format('woff2'),
    url('../../../public/fonts/Gilroy-Semibold.woff') format('woff'),
    url('../../../public/fonts/Gilroy-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../../public/fonts/Gilroy-Medium.eot');
  src: local('Gilroy Medium'), local('Gilroy-Medium'),
    url('../../../public/fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/Gilroy-Medium.woff2') format('woff2'),
    url('../../../public/fonts/Gilroy-Medium.woff') format('woff'),
    url('../../../public/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../../public/fonts/Gilroy-MediumItalic.eot');
  src: local('Gilroy Medium Italic'), local('Gilroy-MediumItalic'),
    url('../../../public/fonts/Gilroy-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/Gilroy-MediumItalic.woff2') format('woff2'),
    url('../../../public/fonts/Gilroy-MediumItalic.woff') format('woff'),
    url('../../../public/fonts/Gilroy-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../../public/fonts/Gilroy-BlackItalic.eot');
  src: local('Gilroy Black Italic'), local('Gilroy-BlackItalic'),
    url('../../../public/fonts/Gilroy-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/Gilroy-BlackItalic.woff2') format('woff2'),
    url('../../../public/fonts/Gilroy-BlackItalic.woff') format('woff'),
    url('../../../public/fonts/Gilroy-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../../public/fonts/Gilroy-UltraLight.eot');
  src: local('Gilroy UltraLight'), local('Gilroy-UltraLight'),
    url('../../../public/fonts/Gilroy-UltraLight.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/Gilroy-UltraLight.woff2') format('woff2'),
    url('../../../public/fonts/Gilroy-UltraLight.woff') format('woff'),
    url('../../../public/fonts/Gilroy-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../../public/fonts/Gilroy-RegularItalic.eot');
  src: local('Gilroy Regular Italic'), local('Gilroy-RegularItalic'),
    url('../../../public/fonts/Gilroy-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/Gilroy-RegularItalic.woff2') format('woff2'),
    url('../../../public/fonts/Gilroy-RegularItalic.woff') format('woff'),
    url('../../../public/fonts/Gilroy-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../../public/fonts/Gilroy-SemiboldItalic.eot');
  src: local('Gilroy Semibold Italic'), local('Gilroy-SemiboldItalic'),
    url('../../../public/fonts/Gilroy-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/Gilroy-SemiboldItalic.woff2') format('woff2'),
    url('../../../public/fonts/Gilroy-SemiboldItalic.woff') format('woff'),
    url('../../../public/fonts/Gilroy-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../../public/fonts/Gilroy-HeavyItalic.eot');
  src: local('Gilroy Heavy Italic'), local('Gilroy-HeavyItalic'),
    url('../../../public/fonts/Gilroy-HeavyItalic.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/Gilroy-HeavyItalic.woff2') format('woff2'),
    url('../../../public/fonts/Gilroy-HeavyItalic.woff') format('woff'),
    url('../../../public/fonts/Gilroy-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../../public/fonts/Gilroy-Extrabold.eot');
  src: local('Gilroy Extrabold'), local('Gilroy-Extrabold'),
    url('../../../public/fonts/Gilroy-Extrabold.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/Gilroy-Extrabold.woff2') format('woff2'),
    url('../../../public/fonts/Gilroy-Extrabold.woff') format('woff'),
    url('../../../public/fonts/Gilroy-Extrabold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../../public/fonts/Gilroy-BoldItalic.eot');
  src: local('Gilroy Bold Italic'), local('Gilroy-BoldItalic'),
    url('../../../public/fonts/Gilroy-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/Gilroy-BoldItalic.woff2') format('woff2'),
    url('../../../public/fonts/Gilroy-BoldItalic.woff') format('woff'),
    url('../../../public/fonts/Gilroy-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../../public/fonts/Gilroy-UltraLightItalic.eot');
  src: local('Gilroy UltraLight Italic'), local('Gilroy-UltraLightItalic'),
    url('../../../public/fonts/Gilroy-UltraLightItalic.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/Gilroy-UltraLightItalic.woff2') format('woff2'),
    url('../../../public/fonts/Gilroy-UltraLightItalic.woff') format('woff'),
    url('../../../public/fonts/Gilroy-UltraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../../public/fonts/Gilroy-LightItalic.eot');
  src: local('Gilroy Light Italic'), local('Gilroy-LightItalic'),
    url('../../../public/fonts/Gilroy-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/Gilroy-LightItalic.woff2') format('woff2'),
    url('../../../public/fonts/Gilroy-LightItalic.woff') format('woff'),
    url('../../../public/fonts/Gilroy-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../../public/fonts/Gilroy-Heavy.eot');
  src: local('Gilroy Heavy'), local('Gilroy-Heavy'),
    url('../../../public/fonts/Gilroy-Heavy.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/Gilroy-Heavy.woff2') format('woff2'),
    url('../../../public/fonts/Gilroy-Heavy.woff') format('woff'),
    url('../../../public/fonts/Gilroy-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../../public/fonts/Gilroy-Thin.eot');
  src: local('Gilroy Thin'), local('Gilroy-Thin'),
    url('../../../public/fonts/Gilroy-Thin.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/Gilroy-Thin.woff2') format('woff2'),
    url('../../../public/fonts/Gilroy-Thin.woff') format('woff'),
    url('../../../public/fonts/Gilroy-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../../public/fonts/Gilroy-ThinItalic.eot');
  src: local('Gilroy Thin Italic'), local('Gilroy-ThinItalic'),
    url('../../../public/fonts/Gilroy-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('../../../public/fonts/Gilroy-ThinItalic.woff2') format('woff2'),
    url('../../../public/fonts/Gilroy-ThinItalic.woff') format('woff'),
    url('../../../public/fonts/Gilroy-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}




* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
  transition: all 1s;
}

html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
  font-size: 20px;
}

span,
a,
li,
label,
h1 {
  transition: all 1s;
  font-family: "Poppins";
  color: #000;
}

/*--------------------*/

input, select {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  font: inherit;
  color: inherit;
  appearance: none;
}

input[type="checkbox"] {
  appearance:auto;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select_arrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.select_arrow::before {
  content: "";
  display: block;
  background-image: url("../../../public/img/svg/AltArrowDownSelect.svg");
  width: 17px;
  height: 17px;
  margin-left: 5px;
  position: absolute;
  pointer-events: none;
}

#root {
  height: 100%;
}

/* [class*="_container"] {
  padding-inline: 10px;
} */

.leaflet-touch,
.leaflet-bar {
  border: none !important;
}

.leaflet-bar a{
  border: none !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 5px !important;
  background: #FFF !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.leaflet-control-zoom-in span,
.leaflet-control-zoom-out span {
  color: #000;
  font-family: Gilroy;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.leaflet-control-zoom-out{
  margin-top: 5px !important;
}

.leaflet-bottom, .leaflet-right{
  display: none !important;
}