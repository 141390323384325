@import '../../consts/variables';

.problemCard {
  padding: 8px;
  border-radius: 10px;
  background: rgba($clr-gray, 0.15);
}

.problemCardDivider {
  background: rgba($clr-gray, 0.3);
}

.problemImageContainer {
  position: relative;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  height: 122px;
}

.problemImage {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.problemImageOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
}

.problemImageHeader {
  padding: 5px;
}

.problemBubble {
  border-radius: 8px;
  padding: 3px 8px;
}

.problemBubbleGray {
  background-color: rgba($clr-black, 0.5);
}

.problemBubbleBlack {
  background-color: rgba($clr-black, 1);
}

.problemImageInfo {
  padding: 10px;
}

.problemEditButton {
  background: rgba($clr-white, 1);
  flex-grow: 1;
  border-radius: 10px;
  padding: 12px 16px;
}

.problemDeleteButton {
  background: rgba($clr-white, 1);
  border-radius: 10px;
  padding: 12px;
}

.fullHeight {
  height: 100%;
}

.growable {
  flex-grow: 1;
}

.paddedBottom {
  padding-bottom: 6px;
}

@media screen and (max-width: 900px) {
  .buttonsContainer {
    justify-content: space-between;
  }
  .problemEditButton {
    max-width: 70%;
  }
}

@media screen and (max-width: 327px) {
  .problemEditButton {
    max-width: 65%;
  }
}
