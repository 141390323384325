@font-face {
  font-family: 'Gilroy';
  src: url('./../fonts/Gilroy-Medium.eot');
  src: url('./../fonts/Gilroy-Medium.eot') format('embedded-opentype'),
    url('./../fonts/Gilroy-Medium.woff') format('woff'),
    url('./../fonts/Gilroy-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'GilroyBold';
  src: url('./../fonts/Gilroy-Bold.eot');
  src: url('./../fonts/Gilroy-Bold.eot') format('embedded-opentype'),
    url('./../fonts/Gilroy-Bold.woff') format('woff'),
    url('./../fonts/Gilroy-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'GilroyRegular';
  src: url('./../fonts/Gilroy-Regular.eot');
  src: url('./../fonts/Gilroy-Regular.eot') format('embedded-opentype'),
    url('./../fonts/Gilroy-Regular.eot') format('woff'),
    url('./../fonts/Gilroy-Regular.eot') format('truetype');
}

.paymentFormContainer {
  display: flex;
  flex-direction: column;
  padding: 20px 20%;
  width: 100%;
  margin: 0 auto;
  background: #fff;
  font-family: 'Gilroy';
}

.paymentFormTitle {
  margin-bottom: 40px;
  font-size: 50px;
  font-family: 'Gilroy';
}

.formInput {
  padding: 12px;
  margin-bottom: 20px;
  background-color: #f6f6f6;
  border-radius: 5px;
  border: none;
  display: block;
  width: 100%;
}

.formLabel {
  margin-bottom: 10px;
  color: #909090;
  display: block;
  font-family: 'Gilroy';
}

.submitBtn {
  color: #ffffff;
  padding: 14px 20px;
  margin: 0;
  background-color: #000000;
  border-radius: 5px;
  cursor: pointer;
}

.formGroupInline {
  display: flex;
  justify-content: space-between;
}

.formGroupInline .formGroup {
  flex: 1;
}

.formGroupInline > div {
  flex-basis: 45%;
}

.errorMessage {
  color: orange;
  margin-bottom: 10px;
  margin-top: -10px;
}

@media screen and (max-width: 900px) {
  .paymentFormContainer {
    padding: 20px;
  }

  .paymentFormTitle {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  .submitBtn {
    width: 100%;
  }
}
