.map_info {
  padding: 0 5px;
  position: relative;
  z-index: 1000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 67px;
  border-radius: 5px;
  background: #FFF;
  width: 353px;
}

.place span {
  opacity: 0.5;
  color: #000;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.place p {
  margin-top: 5px;
  color: #000;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.coordination span {
  opacity: 0.5;
  color: #000;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.coordination p {
  margin-top: 5px;
  color: #000;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.3px;
}

.map {
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 300px;
  width: 100%;
  position: relative;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  border-radius: 5px;
}