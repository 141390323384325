.input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #F6F6F6;
  border-radius: 5px;
}

.block_input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 9.5px;
}
.block_input input {
  border-radius: 5px;
  width: 100%;
  max-width: 370px;
  height: 47px;
  border-radius: 5px;
  background: #F6F6F6;
  color: #000;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0 15px;
}
.block_input label {
  color: #000;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.block_input select {
  width: 100%;
  height: 47px;
  border-radius: 5px;
  background: #F6F6F6;
  color: #000;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.block_input option {
  background: #F6F6F6;
  color: #000;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.select_arrow {
  border-radius: 5px;
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #F6F6F6;
  padding: 0 15px;
}
.select_arrow::before {
  content: "";
  display: block;
  background-image: url("../../../public/img/svg/AltArrowDownSelect.svg");
  width: 17px;
  height: 17px;
  margin-left: 5px;
  position: absolute;
  pointer-events: none;
}