.flex {
  display: flex;
}
.alignCenter {
  align-items: center;
}
.alignStart {
  align-items: flex-start;
}
.alignEnd {
  align-items: flex-end;
}
.alignBaseline {
  align-items: baseline;
}
.justifyCenter {
  justify-content: center;
}
.justifyStart {
  justify-content: flex-start;
}
.justifyEnd {
  justify-content: flex-end;
}
.justifyBetween {
  justify-content: space-between;
}
.directionColumn {
  flex-direction: column;
}
.directionRow {
  flex-direction: row;
}
.wrap {
  flex-wrap: wrap;
}