@import '../../consts/variables';

.activeSubscriptionCard {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.activeSubscriptionCardBar {
  grid-column: span 2;
}

.activeSubscriptionsList {
  margin-top: 20px;

  @media (min-width: $desktop-width) {
    margin-top: 30px;
  }
}

.growable {
  flex-grow: 1;
}