@font-face {
  font-family: 'Gilroy';
  src: url('./../../fonts/Gilroy-Medium.eot');
  src: url('./../../fonts/Gilroy-Medium.eot') format('embedded-opentype'),
    url('./../../fonts/Gilroy-Medium.woff') format('woff'),
    url('./../../fonts/Gilroy-Medium.ttf') format('truetype');
}

.filters {
  border-top: 3px solid #000000;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filtersContainer {
  padding: 40px 32px;
  max-width: 1480px;
  width: 100%;
}

.filtersContainer * {
  font-family: 'Gilroy';
}

.mainLabel {
  margin-bottom: 10px;
  display: block;
  color: #000000 !important;
}

.checkbox {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  margin-right: 5px;
  align-items: center;
  color: #909090;
}

.checkbox input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  display: inline-block;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 5px;
}

.checkbox input:checked ~ .checkmark {
  background-color: #000000;
}

.checkmark:after {
  content: url('./../../static/icons/mdi_tick.svg');
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}

.filtersRow {
  margin-bottom: 30px;
}

.filters button {
  padding: 12px;
  color: #ffffff;
  padding: 14px 20px;
  margin: 0;
  background-color: #000000;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 20px;
}

.date span {
  display: block;
  margin-bottom: 5px;
}

.date input {
  background-color: #ffffff;
  padding: 12px;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .filtersContainer {
    padding: 20px;
  }

  .filtersContent {
    display: block;
  }

  .filtersContentBlock {
    align-items: stretch;
  }

  .dates {
    justify-content: space-between;
  }

  .date,
  .date input {
    width: 100%;
  }
}
