.form {
  padding: 20px 32px;
}

.form * {
  font-family: 'Gilroy';
}

.form button {
  padding: 12px;
  color: #000000;
  padding: 14px 20px;
  margin: 0;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  border: 1px solid #000000;
  display: block;
  width: 100%;
}

.form .deleteButton {
  background-color: #e74444;
  color: #ffffff;
  border: none;
}

.form .saveButton {
  background-color: #000000;
  border: none;
  color: #fff;
}

.label {
  color: #909090;
  display: block;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.label input {
  border: 1px solid #909090;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 5px;
}

.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  max-height: 100vh;
  width: 80vw;
  padding: 10px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1050;
  overflow-y: auto;
}

.errorMessage {
  color: orange;
  margin-bottom: 10px;
}

.form button:disabled {
  opacity: 0.8;
}

@media screen and (max-width: 900px) {
  .Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.9);
    max-height: 100vh;
    width: 90vw;
    padding: 10px;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1050;
    overflow-y: auto;
  }
}
