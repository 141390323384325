@import '../../consts/variables';

.promo {
  min-height: max(100vh, 800px);
  height: 100%;
  position: relative;
  background: url('../../static/bg/homePagePromoBg.jpg') no-repeat 50%/cover;

  &Header {
    margin-block: 40px;
  }

  @media (max-width: $desktop-width) {
    &Menu {
      position: fixed;
      background-color: rgba($clr-grayblue, 0.9);
      height: 100%;
      width: 300px;
      right: -300px;
      top: 0;
      bottom: 0;
      padding-top: min(300px, 20vh);
      transition: 0.3s right ease-in-out;

      &Open {
        right: 0;
      }

      &Btn {
        z-index: 10;
        position: fixed;
        right: 50px;
        top: 50px;
        border-radius: 8px;
        padding: 6px;
        color: $clr-gray;

        &:hover {
          background-color: rgba($clr-white, 0.1)
        }
      }
    }
  }

  &Title {
    max-width: 65%;

    @media (min-width: $desktop-width) {
      max-width: 50%;
    }
  }

  &HeaderBtn {
    background-color: transparent;
    border: 1px solid $clr-white;
    border-radius: 4px;
    padding: 12px 32px;
    transition: 0.2s background-color ease-in-out;

    &:hover {
      background-color: rgba($clr-white, 0.1);
    }
  }

  &Btn {
    background-color: orange;
    border-radius: 4px;
    padding: 12px 32px;
    transition: 0.2s background-color ease-in-out;

    &:hover {
      background-color: rgba(orange, 0.9);
    }
  }

  &Footer {
    height: 25%;
  }
}

.flexStart {
  align-self: flex-start;
}

.fullHeight {
  height: 100%;
}

.clickable {
  cursor: pointer;
}