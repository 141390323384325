@import '../../consts/variables';

.select {
  &Field {
    display: block;
    background: rgba($clr-gray, 15%);
    font-size: 18px;
    font-weight: 500;
    padding: 15px 20px;
    margin: 0;
    border: 1px solid rgba($clr-gray, 30%);
    border-radius: 4px; 
    width: 100%;
  }

  &Wrapper {
    position: relative;
  }
  &Arrow {
    position: absolute;
    top: 20px;
    right: 16px;
    transform: rotate(90deg);
  }
}