.workerDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.workerDetailsContainer * {
  font-family: 'Gilroy';
}

.blockContent {
  max-width: 1480px;
  width: 100%;
  padding: 60px 32px;
}

.grayBg {
  background-color: #f2f2f2;
  width: 100%;
}

.blockContent h1 {
  font-size: 30px;
  margin-bottom: 20px;
}

.workerDetails {
  margin-bottom: 30px;
  margin-top: 30px;
}

.workerDetails h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.workerDetails p {
  font-size: 16px;
  margin-bottom: 5px;
  color: #555;
}

.workerProblems {
  margin-bottom: 30px;
}

.workerProblems strong {
  font-size: 20px;
  display: block;
  margin-bottom: 10px;
  color: #333;
}

.currentProblems,
.finishedProblems {
  margin-bottom: 20px;
}

.currentProblems,
.finishedProblems {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.problemDetails {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #f1f1f1;
}

.problemDetails p {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px;
}

.cardBlock {
  width: 100%;
  margin-bottom: 5px;
}

.infoLabel {
  color: #909090;
  font-weight: normal;
}

.problemDetails img {
  max-width: 30%;
  height: auto;
  border-radius: 4px;
}

.mapContainer {
  margin-top: 10px;
  margin-bottom: 10px;
}

.workerHistory {
  margin-bottom: 30px;
}

.workerHistory strong {
  font-size: 20px;
  display: block;
  margin-bottom: 10px;
  color: #333;
}

.currentProblemsHistory {
  list-style-type: none;
  padding: 0;
}

.historyItem {
  background-color: #fff;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.problemDetails {
  margin-top: 10px;
}

.problemDetails p {
  font-size: 14px;
  margin-bottom: 5px;
  color: #555;
}

.problemInfo {
  font-size: 14px;
  margin-bottom: 5px;
  line-height: 1.5;
  max-width: 100%;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.problemInfo:last-child {
  margin-bottom: 0;
}

.problemInfo strong {
  font-weight: bold;
  color: #333;
}

.problemInfo span {
  font-weight: normal;
}

.pricing {
  text-align: center;
  margin-bottom: 60px;
}

.pricingTitle {
  font-size: 36px;
  color: #333;
  margin-bottom: 40px;
}

.pricingPlans {
  display: flex;
  justify-content: space-between;
}

.pricingPlan {
  flex-basis: 30%;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 5px;
}

.planTitle {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.planPrice {
  font-size: 36px;
  color: #007bff;
  margin-bottom: 10px;
}

.planDescription {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 18px;
  text-decoration: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
}

.workerInfoBox {
  padding: 40px 32px 40px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.workerContent {
  max-width: 1480px;
  width: 100%;
}

.editButton {
  color: #000000;
  padding: 14px 20px;
  margin: 0;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #ddd;
  font-weight: bold;
}

.editButton span {
  font-family: 'Gilroy';
  display: inline-block;
  margin-left: 5px;
}

.editButton img {
  display: inline-block;
  margin: 0;
}

.label {
  color: #b5b5b5;
  font-family: 'Gilroy';
}

.info {
  font-family: 'Gilroy';
  margin-bottom: 10px;
}

.workerPhoto {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: block;
  object-fit: cover;
}

.workerContentBlock {
  gap: 100px;
}

.finishButton {
  padding: 12px 15px;
  background-color: #48ae4c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
}

.deleteButton {
  padding: 12px 15px;
  background-color: #e74444;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
}

.uploadButton {
  display: inline-block;
  padding: 12px 15px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 5px;
}

.uploadButton:hover {
  background-color: #e0e0e0;
}

.sectionTitle {
  font-size: 36px;
  color: #909090;
  padding-bottom: 20px;
  border-bottom: 3px solid #000000;
  margin-bottom: 40px;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: #f2f2f2;
  margin-bottom: 5px;
}

.workerDetailsContainer input[type='file'] {
  background-color: transparent;
  position: relative;
  cursor: pointer;
  color: #909090;
  margin-bottom: 10px;
}

.workerDetailsContainer input[type='file']::file-selector-button {
  border: none;
  outline: none;
  background-color: transparent;
  border-bottom: 1px solid #909090;
  width: fit-content;
  padding-bottom: 10px;
  margin-left: 20px;
  color: #000000;
  cursor: pointer;
}

.workerDetailsContainer input[type='file']::before {
  content: url('./../img/uiw_paper-clip.svg');
  width: 18px;
  height: 19px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 1px;
}

.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  max-height: 100vh;
  width: 80vw;
  padding: 10px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1050;
  overflow-y: auto;
}

.form button {
  padding: 12px;
  color: #000000;
  padding: 14px 20px;
  margin: 0;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  border: 1px solid #000000;
  display: block;
  width: 100%;
}

.form .deleteButton {
  background-color: #e74444;
  color: #ffffff;
  border: none;
}

.form .saveButton {
  background-color: #000000;
  border: none;
  color: #fff;
}

@media screen and (max-width: 900px) {
  .workerPhoto {
    width: 60px;
    height: 60px;
  }

  .workerContent {
    display: block;
  }

  .workerContentBlock {
    gap: 20px;
  }

  .workerInfoBox {
    padding: 20px;
  }

  .blockContent {
    padding: 20px;
  }

  .workerContentBlock {
    display: block;
  }

  .Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.9);
    max-height: 100vh;
    width: 90vw;
    padding: 10px;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1050;
    overflow-y: auto;
  }
}

@media (max-width: 800px) {
  .currentProblems,
  .finishedProblems {
    grid-template-columns: repeat(1, 1fr);

    .infoBox {
      width: 100%; /* Занимает всю доступную ширину */
      height: auto; /* Высота блока адаптируется под контент */
      overflow-y: visible; /* Убираем прокрутку, так как блок будет подстраиваться под контент */
    }
  }
}