@import '../../consts/variables';

.pricing {
  padding-block: 10%;
  // min-height: 100vh;
  height: 100%;
  background: transparent;

  @media (min-width: $desktop-width) {
    padding-block: 20% 20%;
  }

  &Grid {
    display: grid;
    gap: 64px;

    @media (min-width: $desktop-width) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.fullHeight {
  height: 100%;
}