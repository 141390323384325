.header_save {
  cursor: pointer;
  border-bottom: 2px solid rgba(205, 205, 205, 0.5);
  padding-bottom: 5px;
}
.header_save span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #000;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.header_save span::before {
  content: "";
  display: block;
  background-image: url("/public/img/svg/CheckCircle.svg");
  width: 17px;
  height: 17px;
  margin-right: 5px;
}

.header__title h2 {
  color: rgba(0, 0, 0, 0.5);
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.header__title h2 strong {
  color: #000;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.block_image {
  background-size: cover;
  background-position: center;
  margin: 20px 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 120px;
  max-width: 370px;
  border-radius: 10px;
}

.block_text {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
}
.block_text span {
  color: #FFF;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 88.226%;
}

.block_input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 9.5px;
}
.block_input label {
  color: #000;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.block_input input {
  width: 100%;
  max-width: 370px;
  height: 47px;
  border-radius: 5px;
  background: #F6F6F6;
  color: #000;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.block_input select,
.block_input option {
  padding: 0 15px;
  color: #000;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.block_input select {
  height: 47px;
  border-radius: 5px;
  background: #FFF;
}

.block_map {
  margin-top: 20px;
}

.block_delete {
  border-bottom: 2px solid rgba(205, 205, 205, 0.5);
  border-top: 2px solid rgba(205, 205, 205, 0.5);
  padding: 10px 0;
  background-color: #FFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}
.block_delete span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #000;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.block_delete span::before {
  content: "";
  display: block;
  background-image: url("/public/img/svg/TrashBinMinimalistic.svg");
  width: 17px;
  height: 17px;
  margin-right: 5px;
}

.row {
  margin: 15px 0 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.block_status {
  cursor: pointer;
  -webkit-transition: 1s all;
  transition: 1s all;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 10px 30px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: #EAEAEA;
}
.block_status span {
  -webkit-transition: 1s all;
  transition: 1s all;
  color: #000;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.5;
}
.block_status.active {
  -webkit-transition: 1s all;
  transition: 1s all;
  background: #000;
}
.block_status.active span {
  -webkit-transition: 1s all;
  transition: 1s all;
  opacity: 1;
  color: #FFF;
}

.input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #F6F6F6;
  border-radius: 5px;
  padding: 0 15px;
}
.input::after {
  content: "";
  display: block;
  background-image: url("/public/img/svg/pen.svg");
  width: 17px;
  height: 17px;
}

.toggleMapButton {
  background-color: #FFF;
  width: 100%;
  margin-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.toggleMapButton span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #000;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.toggleMapButton span::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("/public/img/svg/PointOnMap.svg");
  margin-right: 5px;
}