@import '../../consts/variables';

.features {
  padding-block: 80px;

  @media (min-width: $desktop-width) {
    padding-block: 0;
    position: absolute;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }
  
  &Grid {
    max-width: 500px;
    margin: 0 auto;
    display: grid;
    padding: 8px;
    background: $clr-grayblue;
    border-radius: 8px;

    @media (min-width: $desktop-width) {
      padding: 32px 8px;
      max-width: unset;
      margin: 0;
      grid-template-columns: repeat(4, 1fr);
    }

    &Item {
      padding-block: 48px;
      padding-inline: 32px;
      min-height: 200px;

      &:not(:last-child) {
        border-bottom: 1px solid rgba($clr-white, 0.6);
      }

      @media (min-width: $desktop-width) {
        padding-block: 0;

        &:not(:last-child) {
          border-bottom: none;
          border-right: 1px solid rgba($clr-white, 0.6);
        }
      }
    }
  }
}