.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container * {
  font-family: 'Gilroy';
}

.blockContent {
  max-width: 1480px;
  width: 100%;
  padding: 60px 32px;
}

.blockContent h1 {
  font-size: 60px;
  margin-bottom: 60px;
  font-weight: bold;
}

.checkbox {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  margin-right: 5px;
  align-items: center;
  color: #909090;
}

.checkbox input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  display: inline-block;
  height: 25px;
  width: 25px;
  background-color: #eaeaea;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox input:checked ~ .checkmark {
}

.checkmark:after {
  content: url('../../static/icons/tick-dark.svg');
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkbox span {
  margin-right: 5px;
  color: #909090;
}

.search {
  background-color: #f6f6f6;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  padding: 12px 10px;
  display: flex;
  gap: 10px;
}

.search input {
  min-width: 200px;
}

.search img {
  width: 20px;
  height: 20px;
}

.nametotalClients {
  margin-top: 40px;
  font-size: 26px;
  color: #333;
  margin-bottom: 10px; /* Отступ снизу для параграфа */
}

.totalClients {
  margin-top: 20px;
  font-size: 16px;
  color: #909090;
  margin-bottom: 10px; /* Отступ снизу для параграфа */
}

.totalClients b {
  color: #000000;
}

.tableContainer {
  overflow-x: scroll;
}

.clientsTable {
  margin-top: 40px;
  width: 100%;
  border-collapse: collapse;
}

.clientsTable th,
.clientsTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.clientsTable th {
  background-color: #f2f2f2;
}

.clientsTable tr:nth-child(even) {
  background-color: #f2f2f2;
}
.clientsTable tr:hover {
  background-color: #ddd;
}

@media screen and (max-width: 900px) {
  .blockContent {
    padding: 20px;
  }

  .blockContent h1 {
    font-size: 24px;
  }

  .optionsContainer {
    display: block;
  }

  .optionsBlock {
    display: block;
  }

  .optionsBlock > * {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .optionsContainer * {
    font-size: 15px;
  }

  .checkbox {
    margin-right: 0;
  }
}