.dashboardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 70px 32px;
}

.contentBlock {
  max-width: 1480px;
  width: 100%;
}

.workerMap {
  flex: 1;
  height: 480px;
  margin-bottom: 80px;
}

// .infoBox {
//   flex: 1;
//   background-color: #f5f5f5;
//   border-radius: 8px;
//   padding: 20px;
//   height: 850px;
//   width: 750px; /* Фиксированная ширина окна */
//   overflow-y: auto; /* Добавление вертикальной прокрутки при необходимости */
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Необязательно, добавляет тень вокруг блока */
// }

.infoBoxTitle {
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 40px;
}

.workerInfoBox,
.houseProblemDetailsBox,
.workerFormBox {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 20px;
  background: white;
  margin-bottom: 20px;
}

.assignedProblemsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background: #f8f8f8;
}

/* TABS */
.tab {
  padding: 10px;
  border: none;
  background-color: #ccc;
  cursor: pointer;
}

.activeTab {
  padding: 10px;
  border: none;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

.subTab {
  padding: 10px;
  background-color: #ddd;
  cursor: pointer;
}

.activeSubTab {
  padding: 10px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

/* TABS */

.problemDetail {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.workerPhoto {
  width: 150px;
  height: 150px;
}

.problemPhoto {
  width: 50px;
  height: 150px;
  object-fit: scale-down;
  background-color: #f8f8f8;
  margin-bottom: 10px;
}

.houseProblemsContainer {
  padding: 50px;
}

.houseProblemsListContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.houseProblemsPrivateContainer {
  flex: 0 0 calc(33% - 20px);
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(8, 8, 8, 0.24);
  border-radius: 5px;
  padding: 10px;
  background-color: #f8f8f8;
}

.assignButton {
  margin-top: 10px;
}

.houseProblemsHeader {
  margin-top: 3px;
}

.outerWorkerBox {
  border: 1px solid #ddd;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 20px;
  border-radius: 10px;
}

:global(.leaflet-popup-content-wrapper) {
  width: 600px !important;
  height: 400px !important;
  overflow-y: auto !important;
}

:global(.leaflet-popup-content) {
  width: 100% !important;
  height: 100% !important;
}

.popupContent {
  font-family: Arial, sans-serif;
  color: #333;
  max-width: 100%;
  line-height: 1.5;
  height: 100%;
}

.problemBox {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 5px;
  border: 1px solid #f5f5f5;
}

.assignedProblem {
  background-color: #75b1cf;
}

.problemsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(25% - 20px), 1fr));
  grid-gap: 10px;
}

.houseImageContainer {
  max-width: 310px;
  width: 100%;
}

.houseImage {
  max-width: 310px;
  height: 270px;
  width: 100%;
  border-radius: 5px;
}

.problemImage {
  height: 200px;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
}

.houseDetailItem {
  font-weight: bold;
  margin-bottom: 10px;
}

.houseDetailItem .label {
  display: block;
  font-weight: normal;
  color: #909090;
  margin-bottom: 5px;
}

.problemDetailItem {
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.problemDetailItem .label {
  font-weight: normal;
  color: #909090;
  margin-bottom: 5px;
}

.copyButton {
  margin-left: 10px;
  background-color: transparent;
  padding-bottom: 5px;
  border-bottom: 1px solid #000000;
  color: #000000;
  cursor: pointer;
  font-weight: bold;
}

.ownerInfoContainer {
  border-radius: 5px;
  background-color: #f5f5f5;
  padding: 25px;
}

.ownerInfoTitle {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 15px;
}

.ownerDetailItem {
  margin-bottom: 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.ownerDetailItem .label {
  color: #909090;
  font-weight: normal;
}

.problemsWrapper {
  margin-bottom: 24px;
  margin-top: 60px;
}

.problemsTitle {
  color: #909090;
  font-size: 22px;
}

.errorButton {
  color: #e74444;
  padding: 12px 15px;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 5px;
  font-weight: bold;
  width: 100%;
}

.assignButton {
  padding: 12px 15px;
  background-color: #000000;
  color: rgb(255, 255, 255);
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 5px;
  font-weight: bold;
  width: 100%;
}

@media screen and (max-width: 900px) {
  .dashboardContainer {
    padding: 20px;
  }

  .workerMap,
  .workerMap > * {
    height: 210px !important;
  }

  .houseInfoRow {
    display: block;
  }

  .houseDetails {
    display: block;
  }

  .houseImage {
    margin-bottom: 10px;
    height: 79px;
    width: 100px;
    object-fit: cover;
  }

  .problemsWrapper {
    display: block;
  }

  .problemsTitle {
    margin-bottom: 10px;
  }

  .tab {
    padding: 5px;
    border: none;
    background-color: #ccc;
    cursor: pointer;
  }

  .activeTab {
    padding: 5px;
    border: none;
    background-color: #4caf50;
    color: white;
    cursor: pointer;
  }

  .subTab {
    padding: 5px;
    background-color: #ddd;
    cursor: pointer;
  }

  .activeSubTab {
    padding: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }

  .problemsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 20px), 1fr));
    grid-gap: 10px;
  }

  .problemsTabsList {
    justify-content: space-between;
  }
}

@media screen and (max-width: 600px) {
  .problemsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    grid-gap: 10px;
  }
}
