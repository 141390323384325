@import '../../consts/variables';

.deleteProblemModal {
  @media (min-width: $desktop-width) {
    padding: 60px;
  }

  &Button {
    width: 140px;
  }
}