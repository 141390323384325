@import '../../consts/variables';

.houseCard {
  width: 100%;
  // max-width: 400px;
  margin: 0 auto;

  &Image {
    margin-top: 10px;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    max-height: 300px;
    object-fit: cover;
  }
  
  &Icon {
    padding: 16px 20px 25px 10px;
    border-right: 1px solid rgba($clr-gray, 30%);
  }
  
  &InfoCard {
    border-bottom: 1px solid rgba($clr-gray, 30%);
    padding-block: 7px 17px;
  }
}

.expandable {
  padding-block: 20px;
  border-top: 1px solid rgba($clr-gray, 30%);
  border-bottom: 1px solid rgba($clr-gray, 30%);
  cursor: pointer;

  &Arrow {
    transition: transform 0.2s ease-in-out;
    &Expanded {
      transform: rotate(90deg);
    }
  }
}

.expandableMapBar {
  margin-top: 30px;
}

.growable {
  flex-grow: 1;
}

.clampable {
  overflow: hidden;
  text-overflow: ellipsis;
}

.houseGrid {
  gap: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  &Image {
    width: 100%;
    max-height: 434px;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  &InfoCard {
    border-bottom: 1px solid rgba($clr-gray, 30%);
    max-width: 219px; 
    width: 100%;
    padding-bottom: 20px;
  }

  &EditButton {
    margin-left: auto;
    align-self: start;
  }
}

.breakWord {
  word-break: break-word;
}

.headerWidth {
  max-width: calc(100% - 152px);
}