/* ClientRegister.module.css */
@import '../consts/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  // margin-top: 200px;
}

.container * {
  font-family: 'Gilroy';
}

.container h1 {
  font-size: 40px;
  font-weight: bold;
}

.form {
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  background: white;
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border: 1px solid #e1e1e1;
}

.input,
.checkbox,
.submit-button,
.social-button {
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
}

.input {
  display: block;
  background: rgba($clr-gray, 15%);
  font-weight: 500;
  border-radius: 4px;
}

.submit-button,
.social-button {
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

.submit-button:hover,
.social-button:hover {
  background-color: #45a049;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.success-message {
  color: green;
  margin-top: 10px;
}

.social-section {
  text-align: center;
}

.loginButton {
  padding: 12px 15px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: 1px solid #000000;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 5px;
  font-weight: bold;
}

.registerButton {
  padding: 12px 15px;
  background-color: #000000;
  color: rgb(255, 255, 255);
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 5px;
  font-weight: bold;
}

.or {
  margin-bottom: 10px;
  text-align: center;
}

.errorMessage {
  color: orange;
  margin-bottom: 10px;
}

@media screen and (max-width: 900px) {
  .form {
    width: 100%;
  }
}
