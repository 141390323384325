.header__title {
  margin: 0px 0 15px;
  text-align: center;
}
.header__title h2 {
  color: #000;
  font-family: Gilroy;
  font-size: 35.844px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.block_select_img {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.select_img {
  cursor: pointer;
}
.select_img span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #000;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.camera::before {
  content: "";
  display: block;
  background-image: url("/public/img/svg/Camera.svg");
  width: 19px;
  height: 19px;
  margin-right: 5px;
}

.folder::before {
  content: "";
  display: block;
  background-image: url("/public/img/svg/Folder.svg");
  width: 19px;
  height: 19px;
  margin-right: 5px;
}

.input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  background: #FFF;
  border-radius: 5px;
}
.input::before {
  content: "";
  display: block;
  background-image: url("/public/img/svg/Pen2.svg");
  width: 19px;
  height: 19px;
  margin-right: 10px;
}
.input input {
  width: 100%;
  max-width: 370px;
  height: 47px;
  border-radius: 5px;
  background: #FFF;
  color: #000;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.block_input_time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  margin-top: 30px;
  padding-bottom: 10px;
  background-color: #FFF;
}
.block_input_time label {
  color: #000;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.time {
  color: #000;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-right: 15px;
  background-color: #FFF;
  width: 100%;
  margin-left: 10px;
}
.time select,
.time option {
  background-color: #FFF;
  color: #000;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
}

.add {
  margin: 30px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 20px 50px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  border-radius: 70px;
  background: #000;
}
.add span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #FFF;
  font-family: Gilroy;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.add span::before {
  content: "";
  display: block;
  background-image: url("/public/img/svg/AddCircle.svg");
  width: 17px;
  height: 17px;
  margin-right: 10px;
}

.view {
  cursor: pointer;
  background-color: #FFF;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.view span {
  color: rgba(0, 0, 0, 0.5);
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.view span strong {
  color: #000;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.block_input {
  margin-top: 10px;
  width: 100%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}
.block_input select,
.block_input option {
  color: #000;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.block_input select {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background: #FFF;
}

.preview {
  margin-bottom: 20px;
}
.preview img {
  border: #000 3px solid;
  width: 100%;
  height: 180px;
  border-radius: 20px;
}

.select_arrow {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.select_arrow::before {
  content: "";
  display: block;
  background-image: url("../../../public/img/svg/AltArrowDownSelect.svg");
  width: 17px;
  height: 17px;
  margin-left: 5px;
  position: absolute;
  pointer-events: none;
}