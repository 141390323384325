@import '../../consts/variables';

.iconCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba($clr-gray, 60%);
  border-radius: 50%;

  &Big {
    width: 46px;
    height: 46px;
  }
}

.addProblemButtons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  @media (min-width: $desktop-width) {
    gap: 20px;
  }
}

.problemButton {
  border-radius: 8px;
  padding: 20px 30px 25px;
  box-shadow: rgba($clr-gray, 15%) 2px 2px 2px 0;

  &:hover {
    box-shadow: rgba($clr-gray, 15%) 4px 4px 4px 0;
  }

  &Dark {
    color: rgba($clr-white, 1);
    background: rgba($clr-black, 1);

    &:hover {
      background: rgba($clr-black, 0.9);
    }
  }

  &Light {
    background: rgba($clr-gray, 30%);

    &:hover {
      background: rgba($clr-gray, 15%);
    }
  }

  @media (min-width: $desktop-width) {
    border-radius: 10px;
    padding: 33px;
  }
}

.iconCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba($clr-gray, 60%);
  border-radius: 50%;

  &Big {
    width: 46px;
    height: 46px;
  }
}

.barItem {
  border-bottom: 1px solid rgba($clr-gray, 30%);
  padding-block: 0 20px;
}

.noPadding {
  padding: 0;
}

.noSubscriptionsWarning {
  color: #B9472F;
}

.linkStyle {
  color: #0000FF;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}