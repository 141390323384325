@import '../../consts/variables';

.container {
  max-width: 740px;
  width: 100%;
  margin-inline: auto;
  padding-inline: 12px;

  @media (min-width: $desktop-width) {
    max-width: 1480px;
    padding-inline: 32px;
  }
}

