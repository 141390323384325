.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  max-height: 100vh;
  width: 80vw;
  padding: 10px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1050;
  overflow-y: auto;
}
.button {
  padding: 12px;
  color: #ffffff;
  padding: 14px 20px;
  margin: 0;
  background-color: #000000;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  display: block;
  width: 100%;
  margin-top: 10px;
}

@media screen and (max-width: 900px) {
  .Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.9);
    max-height: 100vh;
    width: 90vw;
    padding: 10px;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1050;
    overflow-y: auto;
  }
}
