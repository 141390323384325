@import '../../consts/variables';

.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  max-height: 110vh;
  min-height: fit-content;
  width: 80vw;
  padding: 10px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 100000;
  overflow-y: auto;
}

.displayNone {
  display: none;
}

.labelImageInput {
  display: block;
  height: 120px;
  width: 100%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}

.houseImage {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: brightness(0.4);
  transition: filter 0.4s ease-in-out;

  &:hover {
    filter: brightness(0.5);
  }
}

.houseImageCoverText {
  height: 100%;
  width: 100%;

  & p {
    z-index: 50;
    filter: brightness(1);
  }
}

.expandable {
  padding-block: 20px;
  border-top: 1px solid rgba($clr-gray, 30%);
  border-bottom: 1px solid rgba($clr-gray, 30%);
  cursor: pointer;

  &Arrow {
    transition: transform 0.2s ease-in-out;
    &Expanded {
      transform: rotate(90deg);
    }
  }
}

.expandableMapBar {
  margin-top: 30px;
}

.growable {
  flex-grow: 1;
}

.Modal img {
  min-height: 400px;
}

@media screen and (max-width: 900px) {
  .Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.9);
    min-height: 90vh;
    max-height: 99vh;
    width: 90vw;
    padding: 10px;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow-y: auto;
    z-index: 100000;
  }
}
