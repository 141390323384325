@import '../../consts/variables';

.input {
  &Field {
    display: block;
    background: rgba($clr-gray, 15%);
    font-size: 18px;
    font-weight: 500;
    padding: 15px 20px;
    border: 1px solid rgba($clr-gray, 30%);
    border-radius: 4px; 
  }
}