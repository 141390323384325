.typesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.typesContainer * {
  font-family: 'Gilroy';
}

.blockContent {
  max-width: 1480px;
  width: 100%;
  padding: 60px 32px;
}

.blockContent h1 {
  font-size: 60px;
  margin-bottom: 60px;
  font-weight: bold;
}

.subsList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(33% - 20px), 1fr));
  grid-gap: 20px;
}

.subCard {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #e6e6e6;
}

.info {
  font-weight: bold;
  font-size: 24px;
}

.label {
  font-weight: normal;
  color: #909090;
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
}

.count {
  font-weight: normal;
}

@media screen and (max-width: 900px) {
  .subsList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    grid-gap: 10px;
  }

  .blockContent {
    padding: 24px;
  }

  .blockContent h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .title {
    font-size: 20px;
  }

  .info {
    font-size: 20px;
  }
}