.fc-button-group .fc-prev-button,
.fc-button-group .fc-next-button {
  border-radius: 50%;
  background-color: #f4f4f4;
  border: none;
}

.fc-button-group .fc-next-button {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.fc-button-group .fc-prev-button {
  border-bottom-right-radius: 50% !important;
  border-top-right-radius: 50% !important;
  margin-right: 10px;
}

.fc-button-group span {
  color: #000 !important;
}

.fc-button-primary:hover {
  color: #fff !important;
}

.fc-button-active {
  color: #fff !important;
}

.fc-today-button {
  background-color: #000 !important;
  color: #fff !important;
}

@media screen and (max-width: 900px) {
  .fc-header-toolbar {
    flex-wrap: wrap;
  }

  .fc-toolbar-chunk:nth-child(2) {
    order: -1;
    min-width: 800px;
    overflow-x: hidden;
    margin-bottom: 10px;
  }
}