.problems_block {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 110px;
  width: 100%;
  border-radius: 5px;
  background: #F2F2F2;
}

.problems_title {
  margin-bottom: 20px;
  border-bottom: 2px solid rgba(58, 58, 58, 0.1);
  padding-bottom: 10px;
}
.problems_title h2 {
  color: #000;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.problems_info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.info_text {
  margin-left: 20px;
}
.info_text span {
  opacity: 0.5;
  color: #000;
  font-family: Gilroy;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.info_images img {
  width: 30px;
  height: 30px;
  margin: 0 5px;
  border-radius: 5px;
}