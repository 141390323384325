@import '../../consts/variables';

.modalOverlay {
  position: fixed; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($clr-black, 70%); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.modalContent {
  background: rgba($clr-white, 1);
  padding: 30px 15px;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba($clr-black, 30%);
  max-width: 500px;
  width: 99%;
}