@import '../../consts/variables';

.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  max-height: 110vh;
  width: 80vw;
  padding: 10px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1050;
  overflow-y: auto;
}

.problemCardsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, auto));
  gap: 10px;
  grid-template-rows: min-content;
  padding: 1px;
}

.problemCardsGridContainer {
  overflow: auto;
  max-height: 80vh;
}

// .problemImageContainer {
//   position: relative;
//   width: 100%;
//   height: 300px;
//   border-radius: 10px;
//   overflow: hidden;

//   @media (min-width: $desktop-width) {
//     height: 300px;
//   }
// }

@media screen and (max-width: 900px) {
  .Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.9);
    max-height: 90vh;
    width: 99.5vw;
    padding: 10px;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1050;
    overflow-y: auto;
  }

  .problemCardsGrid {
    grid-template-columns: repeat(auto-fill, minmax(140px, auto));
  }

  .problemCardsGridContainer::-webkit-scrollbar {
    display: none;
  }
}
