/* WorkerRegistrationForm.module.css */
.container {
  max-width: 500px;
  margin: auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.heading {
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.inputField {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.selectField {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.checkboxContainer {
  display: flex;
  align-items: center;
}

.checkboxLabel {
  margin-left: 5px;
}

.fileInput {
  border: none;
  background-color: #f3f3f3;
}

.submitButton {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #8bc58b;
  color: white;
  cursor: pointer;
}

.submitButton:disabled {
  background-color: #ccc;
}

.submitButtonActive {
  background-color: #4caf50;
}

.errorMessage {
  color: orange;
  margin-bottom: 10px;
}

.form button:disabled {
  opacity: 0.8;
}
