:root {
  --fc-button-bg-color: #f4f4f4;
  --fc-button-text-color: #000000;
  --fc-button-border-color: transparent;
  --fc-button-active-bg-color: #000000;
  --fc-button-active-border-color: transparent;
  --fc-event-bg-color: #ffffff;
  --fc-event-border-color: transparent;
  --fc-event-text-color: #000000;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);
  --fc-today-bg-color: transparent;
  --fc-button-hover-bg-color: #000000;
}

.container span {
  color: #fff !important;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container * {
  font-family: 'Gilroy';
}

.blockContent {
  max-width: 1480px;
  width: 100%;
  padding: 60px 32px;
}

.blockContent h1 {
  font-size: 60px;
  margin-bottom: 60px;
  font-weight: bold;
}

.eventContent {
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 5px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 100%;
  overflow-x: hidden;
}

.eventContent p {
  margin-bottom: 5px;
  padding: 0 1px;
  white-space: normal;
}

.calendar .fc-view-harness {
  overflow-x: scroll;
}

.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  max-height: 100vh;
  width: 80vw;
  padding: 10px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1050;
  overflow-y: auto;
}

.Modal .cancelButton,
.Modal .saveButton,
.Modal .openButton {
  padding: 12px;
  color: #000000;
  padding: 14px 20px;
  margin: 0;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  border: 1px solid #000000;
  display: block;
  width: 100%;
}

.Modal .saveButton {
  background-color: #48ae4c;
  border: none;
  color: #fff;
}

.Modal .cancelButton {
  background-color: #e74444;
  color: #fff;
  border: none;
}

.info {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.label {
  font-weight: normal;
  color: #909090;
  font-family: 'Gilroy';
}

@media screen and (max-width: 900px) {
  .blockContent {
    padding: 24px;
  }

  .blockContent h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.9);
    max-height: 100vh;
    width: 90vw;
    padding: 10px;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1050;
    overflow-y: auto;
  }
}