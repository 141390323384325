@import '../../consts/variables';

.text {
  font-family: Gilroy;
  font-size: 16px;
  line-height: 1.2;

  &Bold {
    font-weight: 700;
  }
  
  &SemiBold {
    font-weight: 600;
  }
  
  &Medium {
    font-weight: 500;
  }
  
  &Regular {
    font-weight: 400;
  }
  
  &Black {
    color: rgba($clr-black, 1);
  }
  
  &Gray {
    color: rgba($clr-gray, 1);
  }
  
  &White {
    color: rgba($clr-white, 1);
  }
  
  &Center {
    text-align: center;
  }
  
  &Start {
    text-align: start;
  }
  
  &End {
    text-align: end;
  }
  
  &Underline {
    text-decoration: underline;
  }

  &Ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
  }
}







