/* SubscriptionPlans.css */

.subscription-plans {
    font-family: Arial, sans-serif;
    margin: 0 auto;
    max-width: 800px;
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.subscription-plans h1 {
    color: #333;
    text-align: center;
    margin-bottom: 30px;
}

.plan {
    background-color: white;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.plan h2 {
    color: #27ae60;
    margin-bottom: 10px;
}

.plan p {
    margin-bottom: 5px;
    line-height: 1.6;
}

.advantages {
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 5px;
}

.advantages h2 {
    margin-bottom: 10px;
}

.advantages ul {
    list-style-type: none;
    padding: 0;
}

.advantages ul li {
    margin-bottom: 5px;
    padding-left: 20px;
    position: relative;
}

.advantages ul li:before {
    content: "✓";
    position: absolute;
    left: 0;
    color: #27ae60;
}
