@import '../../consts/variables';

.availableSubscriptionsBlock {
  background: rgba($clr-gray, 15%);
  border-radius: 4px;
  padding: 20px;
}

.availableSubscriptionCard {
  background: rgba($clr-white, 1);
  border-radius: 4px;
  padding: 20px;
}

.availableSubscriptionsList {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}

.growable {
  flex-grow: 1;
}