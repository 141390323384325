@import '../../consts/variables';

.problemTextButton {
  color: rgba($clr-gray, 1);
  padding: 0;

  &Active, &:hover {
    color: rgba($clr-black, 1);
    font-weight: 600;
  }
}

.problemTabs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.problemTab {
  padding: 4px 4px 17px;
  border-bottom: 1px solid rgba($clr-gray, 1);
  border-radius: 0;
  transition: all 0.3s ease-in-out;

  & .p {
    font-weight: 600;
    color: rgba($clr-gray, 1);
    transition: all 0.3s ease-in-out
  }

  &Active {
    padding-bottom: 15px;
    border-bottom: 3px solid rgba($clr-black, 1);

    & .p {
      font-weight: 700;
      color: rgba($clr-black, 1);
    }
  }
}

.problemsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 54px;

  &Header {
    padding-bottom: 20px;
    border-bottom: 3px solid rgba($clr-black, 1);
  }
}

.problemCardsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, auto));
  gap: 10px;
  grid-auto-rows: min-content;
}

.noPaddingButton {
  padding: 0;
}

// Without scrooll, like in Figma
// .scrollable {
//   width: 100%;

//   overflow: scroll;
//   -ms-overflow-style: none;
//   scrollbar-width: none;

//   &::-webkit-scrollbar {
//     display: none;
//   }
// }