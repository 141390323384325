@import '../../consts/variables';

.problemsInProgressGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px 20px;
}

.doubleSpan {
  grid-column: span 2;
}