@import '../../consts/variables';

.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  max-height: 110vh;
  width: 80vw;
  padding: 10px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1050;
  overflow-y: auto;
}

.displayNone {
  display: none;
}

.labelImageInput {
  display: block;
  height: 120px;
  width: 100%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}

.problemImage {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: brightness(0.4);
  transition: filter 0.4s ease-in-out;

  &:hover {
    filter: brightness(0.5);
  }
}

.problemImageCoverText {
  height: 100%;
  width: 100%;

  & p {
    z-index: 50;
    filter: brightness(1);
  }
}

.statusButton {
  padding: 10px 30px;
  border-radius: 20px;
  transition: 0.3s all ease-in-out;
  background-color: rgba($clr-gray, 0.2);
  color: $clr-gray;
  font-weight: 500;

  &Active {
    background-color: $clr-black;
    color: $clr-white;
    font-weight: 700;
  }
}

.errorMessage {
  color: orange;
  margin-bottom: 10px;
}

@media screen and (max-width: 900px) {
  .Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.9);
    max-height: 98vh;
    width: 87vw;
    padding: 10px;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1050;
    overflow-y: auto;
  }

  .labelImageInput {
    height: 180px;
  }
}
