.block_problem {
  border-radius: 10px;
  background: #EDEDED;
  padding: 10px;
  margin: 0 5px;
}
.block_problem:first-child {
  margin-left: 0px;
}
.block_problem:last-child {
  margin-right: 0px;
}

.problem_img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 280px;
  height: 192px;
  background-size: cover;
  background-position: center;
}

.problem_status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.problem_status span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 5px 15px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  border-radius: 60px;
  background: #000;
  color: #FFF;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.problem_status span:last-child {
  background: rgba(0, 0, 0, 0.2);
}

.problem_date span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #FFF;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.problem_date span::before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  background-image: url("/public/img/svg/CalendarAdd.svg");
  margin-right: 5px;
}

.problem_title {
  margin: 10px 0;
}
.problem_title h2 {
  color: #000;
  font-family: Gilroy;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.problem_type {
  border-bottom: 2px solid rgba(58, 58, 58, 0.1);
  padding-bottom: 20px;
}
.problem_type span {
  color: rgba(0, 0, 0, 0.5);
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.problem_type span strong {
  color: #000;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.problem_nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 30px;
}

.edit {
  width: 198px;
  height: 50px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border-radius: 10px;
  background: #FFF;
}
.edit span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #000;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.edit span::before {
  content: "";
  display: block;
  background-image: url("/public/img/svg/pen.svg");
  width: 17px;
  height: 17px;
  margin-right: 5px;
}

.delete {
  width: 77px;
  height: 50px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border-radius: 10px;
  background: #FFF;
}

.block_problem_small {
  margin: 5px;
  padding: 6px;
  border-bottom: 2px solid rgba(58, 58, 58, 0.1);
  width: 184px;
  height: 230px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border-radius: 6.133px;
  background: #EDEDED;
}

.problem_img_small {
  background-size: cover;
  background-position: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 171.733px;
  height: 118.512px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border-radius: 6.133px;
}

.problem_status_small {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.problem_status_small span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #000;
  width: 51.52px;
  height: 12.345px;
  padding: 3.067px 9.2px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 6.133px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  color: #FFF;
  font-family: Gilroy;
  font-size: 6.587px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 36.8px;
}
.problem_status_small span:last-child {
  background: rgba(0, 0, 0, 0.2);
}

.problem_date_small span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #FFF;
  font-family: Gilroy;
  font-size: 7.36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.problem_date_small span::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-image: url("/public/img/svg/CalendarAdd.svg");
  background-size: cover;
  margin-right: 5px;
}

.problem_title_small {
  margin: 5px 0;
}
.problem_title_small h2 {
  color: #000;
  font-family: Gilroy;
  font-size: 14.72px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.problem_type_small span {
  color: rgba(0, 0, 0, 0.5);
  font-family: Gilroy;
  font-size: 8.587px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.problem_type_small span strong {
  color: #000;
  font-family: Gilroy;
  font-size: 8.587px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.problem_nav_small {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 18px;
}

.edit_small {
  width: 121.44px;
  height: 30.863px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border-radius: 6.133px;
  background: #FFF;
}
.edit_small span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #000;
  font-family: Gilroy;
  font-size: 8.587px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.edit_small span::before {
  content: "";
  display: block;
  background-image: url("/public/img/svg/pen.svg");
  background-size: cover;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.delete_small {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 47.227px;
  height: 30.863px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border-radius: 6.133px;
  background: #FFF;
}
.delete_small img {
  width: 10.427px;
  height: 10.493px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}