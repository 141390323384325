@import '../../consts/variables';

.displayNone {
  display: none;
}

.previewProblemImage {
  border: #9e9e9e 3px solid;
  width: 100%;
  height: 280px;
  border-radius: 20px;
}

.shiftedText {
  margin-top: 15px;
}

.warningModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000;
}

.warningModalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
}

.noSubscriptionsWarning {
  color: #b9472f;
  margin-bottom: 20px;
}

.linkStyle {
  color: #0000ff;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.categoryImage {
  max-width: 100%;
  height: 300px;
  margin-bottom: 20px;
}

.errorMessage {
  color: orange;
  margin-bottom: 10px;
}

@media screen and (max-width: 900px) {
  .Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.9);
    max-height: 90vh;
    width: 87vw;
    padding: 10px;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1050;
    overflow-y: auto;
  }
}
