@import '../../consts/variables';

.guide {
  padding-bottom: 100px;

  &Step {
    &Number {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      background-color: $clr-black;
      position: relative;

      @media (min-width: $desktop-width) {
        height: 144px;
        width: 144px;
      }

      &Inner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-45%);
      }
    }

    &Info {
      max-width: calc(100% - 150px);

      @media (min-width: $desktop-width) {
        max-width: unset;
      }
    }

    &Description {
      margin: 0 auto;
      max-width: 60%;

      @media (min-width: $desktop-width) {
        margin: 0;
        max-width: 40%;
      }
    }

    &Image {
      max-width: 70%;
      margin: 0 auto;

      @media (min-width: $desktop-width) {
        max-width: 40%;
        max-height: 33%;
        margin: 0;
      }
    }
  }
}