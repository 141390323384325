.header {
  width: 100%;
  margin: 30px 0;
}

.header_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.header_title h1 {
  opacity: 0.5;
  color: #000;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.header_title p {
  opacity: 1;
  color: #000;
  font-family: Gilroy;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.header_edit {
  padding: 5px;
  background-color: #FFF;
  border-bottom: 2px solid rgba(205, 205, 205, 0.5);
}

.header_edit span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #000;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.header_edit span::before {
  content: "";
  display: block;
  background-image: url("/public/img/svg/pen.svg");
  width: 17px;
  height: 17px;
  margin-right: 5px;
}

.content {
  width: 100%;
  height: 100%;
}

.content_container {
  width: 100%;
}

.card_photo {
  margin-bottom: 27px;
}

.card_photo img {
  width: 373px;
  height: 300px;
  border-radius: 20px;
}

.card_info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.card_info img {
  border-right: 2px solid rgba(205, 205, 205, 0.5);
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 5px;
  margin-left: 10px;
}

.info_address {
  margin-left: 30px;
}

.info_size {
  margin-left: 80px;
}

.info_address, .info_size {
  border-bottom: 2px solid rgba(205, 205, 205, 0.5);
  height: 50px;
}

.info_address span,
.info_size span {
  opacity: 0.5;
  color: #000;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.info_address p,
.info_size p {
  color: #000;
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.info_address span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.info_address span::before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  background-image: url("/public/img/svg/MapPointWave.svg");
  margin-right: 5px;
}

.toggleMapButton {
  background-color: #FFF;
  width: 100%;
  margin-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 2px solid rgba(205, 205, 205, 0.5);
  border-bottom: 2px solid rgba(205, 205, 205, 0.5);
  padding: 20px 0;
}

.toggleMapButton span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #000;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.toggleMapButton span::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("/public/img/svg/PointOnMap.svg");
  margin-right: 5px;
}

.content_map,
.content_card,
.content_problem,
.content_current_problem,
.content_problem_process,
.content_ready_problem {
  width: 100%;
  margin-bottom: 70px;
}

.problem_title {
  width: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 50px;
}

.problem_title h2 {
  opacity: 0.5;
  color: #000;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.problem_title a {
  color: #000;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}

.problem_title span {
  color: #000;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.problem_choose {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.choose_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  width: 50%;
  height: 132px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 10px;
  background: #000;
}

.choose_block:last-child {
  width: 49.5%;
  margin-left: 5px;
  background: #E7E7E7;
}

.choose_block:last-child .block_text span {
  color: #000;
}

.choose_block .block_text span {
  color: #FFF;
  text-align: center;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.list_problem {
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.list_problem::-webkit-scrollbar-track {
  background-color: transparent;
}

.list_problem::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.list_problem::-webkit-scrollbar {
  width: 0;
}

.content_current_problem_buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.urgent,
.planned {
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background: #FFF;
}

.urgent span,
.planned span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  opacity: 0.4;
  color: #000;
  text-align: center;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.urgent.active,
.planned.active {
  border-bottom: 3px solid rgb(0, 0, 0);
}

.urgent.active span,
.planned.active span {
  opacity: 1;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #000;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.urgent span::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("/public/img/svg/DangerCircle.svg");
  margin-right: 5px;
}

.planned span::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("/public/img/svg/History.svg");
  margin-right: 5px;
}

.process_title {
  margin-bottom: 20px;
  opacity: 0.5;
}

.process_title h2 {
  color: #000;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ready_problem_block {
  margin-top: 20px;
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 60px;
}

.ready_problem_block span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #000;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ready_problem_block span::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("/public/img/svg/CheckCircle.svg");
  margin-right: 5px;
}

.block_list {
  width: 100%;
  display: grid;
  grid-template: 1fr/1fr 1fr;
}

.ready_problem_block {
  cursor: pointer;
  border-top: 2px solid rgba(205, 205, 205, 0.5);
  border-bottom: 2px solid rgba(205, 205, 205, 0.5);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  text-align: center;
}


.errorMessage {
  color: red;
  font-weight: bold;
  font-size: 1.5em; /* Или любой другой размер шрифта, который вы считаете подходящим */
}