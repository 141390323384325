@import '../../consts/variables';

.ending {
  background: url('../../static/bg/homePageEndingBg.jpg') no-repeat 50%/cover;
  padding-block: 120px 60px;
  height: max(80%, 680px);

  &Title {
    max-width: 65%;
  }

  &Btn {
    background-color: orange;
    border-radius: 4px;
    padding: 12px 32px;
    transition: 0.2s background-color ease-in-out;

    &:hover {
      background-color: rgba(orange, 0.9);
    }
  }

  &Copyright {
    color: rgba($clr-white, 0.8);
  }
}

.flexStart {
  align-self: flex-start;
}

.fullHeight {
  height: 100%;
}

.hidden {
  visibility: hidden;
}